<template>

   <v-row v-if="fieldType=='file'">
      <v-col md="9" cols="12">
         <div class="form-text" :class="{'bordered': bordered}">
            <div class="form-label">
               <span class="caption">{{label}}</span><br>
            </div>
            <span class="body-2 text px-1" v-if="fieldType=='file'">
               {{(value === '' || value === null || value === undefined) ? bordered ? '' : '-' : value}}
            </span>
         </div>
      </v-col>
      <v-col md="3" cols="12">
         <v-btn color="purple darken-3 white--text" class="ma-2 mx-3 m-auto report-btn" large :to="buttonLink" target="_blank">View</v-btn>
      </v-col>
   </v-row>

   <div class="form-text" :class="{'bordered': bordered}" v-else>
      <div class="form-label">
         <span class="caption">{{label}}</span><br>
      </div>

      <span class="body-2 text px-1" v-if="fieldType=='string'">
         {{(value === '' || value === null || value === undefined) ? bordered ? '' : '-' : value}}
      </span>
      
      <div class="d-flex text" v-else-if="fieldType=='button'">
         <div class="py-3 pr-3 align-center d-flex">
            <v-btn :href="buttonLink" target="_blank" small color="grey darken-1" class="white--text">{{buttonText}}</v-btn>
         </div>
      </div>

   </div>
</template>

<script>
   export default {
      name: "FormDisplay",
      props: ["fieldType", "value", "label", "buttonLink", "buttonText", "bordered"]
   }
</script>

<style lang="scss" scoped>
.form-text {
   // border: 1px solid #eee;
   position: relative;
   padding-left: 11px;
   min-height: 55px;

   .form-label {
      background: #fff;
      position: absolute;
      top: -11px;
      padding-inline-start: 2px;
      padding-inline-end: 2px;
   }

   span.text {
      position: absolute;
      bottom: 16px;
   }

   div.text {
      position: absolute;
      bottom: 0;
   }

   &.bordered {
      border-collapse: collapse;
      border-color: rgba(94, 86, 105, 0.22);
      border-style: solid;
      border-width: 1px;
      border-radius: 5px;
   }
}
</style>