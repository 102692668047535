<template>
   <v-form ref="redeem-record-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Redeem record</h3>
         </v-col>
         <v-col cols="12">
            <v-btn color="secondary" class="m-auto px-8" large @click.prevent="$router.go(-1)" :disabled="isFetching">Back</v-btn>
         </v-col>
         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <FormDisplay fieldType="number" v-model="data.user_id" label="User" :bordered="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormDisplay fieldType="string" v-model="data.delivery_method" label="Delivery method" :bordered="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormDisplay fieldType="string" v-model="data.total" label="Total" :bordered="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <v-col md="12" cols="12">
            <v-card>
               <v-card-title><h5>Redeem item</h5></v-card-title>
               <v-card-text>
                  <v-row v-for="(item, index) in data.items" :key="`redeem-item-${index}`">
                     <v-col md="6" cols="12">
                        <FormDisplay fieldType="string" v-model="item.quantity" label="Quantity" :bordered="true" />
                     </v-col>
                     <!-- <v-col md="6" cols="12">
                        <FormDisplay fieldType="string" v-model="item.data" label="Credit amount" :bordered="true" />
                     </v-col> -->
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <!-- <v-col cols="12">
            <v-btn color="primary" class="px-8 form-btn" large :disabled="isFetching" type="submit">Save</v-btn>
            <FormDeleteBtn :removeAction="removeItem" />
         </v-col> -->
      </v-row>
   </v-form>
</template>

<script>
import FormControl from '@/components/form/FormControl'
import FormDisplay from '@/components/form/FormDisplay'
import { mapActions, mapState } from 'vuex'

export default {
   name: 'RedeemRecordDetail',
   components: {
      FormControl,
      FormDisplay
   },
   data() {
      return {
         data: {
            id: _.toNumber(this.$route.params.id),
            user_id: 0,
            items: [],
            delivery_method: '',
            total: 0,
         },
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),
      async initData() {
         const resp = await this.sendRequest({ type: 'get_redeem_item_by_id', data: { id: this.data.id } })
         if (!resp) return
         this.data = {
            ...this.data,
            user_id: resp.data.user_id,
            items: resp.data.items,
            delivery_method: resp.data.delivery_method,
            total: resp.data.company.total,
         }
      },
      async formSubmit() {
         console.log('save')
         const isValid = this.$refs['redeem-record-form'].validate()
         if (isValid) {
            const resp = await this.updateItem()
            this.setDialogMessage({ 
               message: resp ? 'Successful' : 'Failed', 
               isError: !resp, 
               returnLink: (this.data.id === undefined ? { name: 'redeemRecord' } : null )
            })
            this.setShowDialog(true)
         }
      },
      async updateItem() {
         console.log('update')
         const formdata = {
            data_type: 'redeem_record_data',
            id: this.data.id,
            user_id: this.data.user_id,
            items: this.data.items,
            delivery_method: this.data.delivery_method,
            total: this.data.total,
         }
         return await this.sendRequest({ type: 'get_redeem_item_by_id', data: formdata })
      },
   },
   async mounted() {
      await this.initData()
   }
}
</script>
